import React, {useRef} from "react";
import './headline.scss';
import Modal from '../modal/modal';
import WhatIs from "../what-is/what-is";

export default function Headline() {
    const modalRef1 = useRef();
    return (
        <section className={'headline'}>
            <h1>Settimane Fairtrade: <br/>
                per le persone, per il pianeta</h1>
            <h2>
                Puoi contribuire anche tu a <strong>cambiare il mondo</strong>.
            </h2>

            <div className={'teaser-text'}>
                <p>Un mondo in cui:</p>
                <ul>
                    <li>
                        Tutte le persone abbiano una vita dignitosa
                    </li>
                    <li>
                        Il commercio sia più giusto
                    </li>
                    <li>Affrontiamo la crisi climatica insieme agli agricoltori</li>
                </ul>
            </div>

            <a href="#partners">
                Partecipa alle Settimane Fairtrade
            </a>
            <Modal ref={modalRef1}>
                <WhatIs/>
            </Modal>
        </section>
    )
}