import React from "react";
import './partners.scss';
import {StaticImage} from "gatsby-plugin-image";
import vegetables from "../../images/icons/vegetables.svg";
import rice from "../../images/icons/rice.svg";
import thankYou from "../../images/icons/thankyou.svg";
import carrerfourJPG from '../../images/partners/Carrefour_Express-Settimane Fairtrade 2022.jpg';
// import carrerfourPDF from '../../images/partners/Carrefour_Settimane_Fairtrade_2021.pdf';
import carrerfourPDF from '../../images/partners/Carrefour-Iper_Express_2023.pdf';
// import aldiPDF from '../../images/partners/ALDI_Volantino 11.10.2021.pdf';
// import aldiPDF from '../../images/partners/ALDI_Volantino 18.10.2021.pdf';
// import aldiJpg from '../../images/partners/aldi_4.10.22.jpeg';
// import aldiPDF from '../../images/partners/ALDI_Rose_Settimane Fairtrade 2023.pdf';
import aldiPDF from '../../images/partners/ALDI 9-16.10.23.pdf';
// import lidlJpg from '../../images/partners/LIDL-dal-18-10-al-24-10.jpg';
import PAMJpg from '../../images/partners/pam_2022.jpeg';
// import lidlPdf from '../../images/partners/LIDL_Settimane Fairtrade 2022.pdf';
// import lidlPdf from '../../images/partners/LIDL_Settimane Fairtrade 2022.jpeg';
// import lidlPdf from '../../images/partners/Lidl_Settimane Fairtrade 2023.pdf';
import lidlPdf from '../../images/partners/LIDL 9-16.10.23.pdf';
// import coopJpg from '../../images/partners/Coop_Settimane Fairtrade 2023.jpg';
import coopJpg from '../../images/partners/Coop_Settimane_Fairtrade_2023.jpg';
import insJpg from '../../images/partners/INS_Settimane_Fairtrade_2023.jpg';
import pamJpg from '../../images/partners/PAM_Settimane_Fairtrade_2023.jpg';

export function useToggle(initialValue = false) {
    const [value, setValue] = React.useState(initialValue);
    const toggle = React.useCallback(() => {
        setValue(v => !v);
    }, []);
    return [value, toggle];
}

export default function Partners() {
    const [isOn, toggleIsOn] = useToggle();
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    return (
        <section className={'partners'} id={'partners'}>
            <h3>Visita i punti vendita che hanno aderito</h3>
            <p className={'partners-intro'}>
                A ottobre fare la <strong>'spesa giusta'</strong> è ancora più conveniente, grazie alla
                campagna <strong>Settimane Fairtrade</strong><br/>
                in collaborazione con:
            </p>
            <ul className={'partners-list'}>
                {/*<li onClick={() => openInNewTab(aldiJpg)} >*/}
                {/*<li >*/}
                <li onClick={() => openInNewTab(aldiPDF)} >

                <div className={'discount-rate'}>promozioni</div>
                    <div className={'aldi'}>
                        <StaticImage
                            src="../../images/partners/aldi-logo.png"
                            alt="Aldi"
                            placeholder="blurred"
                        />
                    </div>
                    <div>
                        <p>scopri i prodotti da</p>
                        {/*<span>ALDI</span>*/}
                        <a href={aldiPDF} target={'_blank'}>ALDI</a>
                    </div>
                </li>
                <li onClick={() => openInNewTab(carrerfourPDF)} >
                {/*<li >*/}
                    <div className={'discount-rate'}>fino al 20%</div>
                    <div className={'carrerfour'}>
                        <StaticImage
                            src="../../images/partners/carrefour-logo.png"
                            alt="Carrefour"
                            placeholder="blurred"
                        />
                    </div>
                    <div>
                        <p>scopri i prodotti da</p>
                        {/*<span>CARREFOUR</span>*/}
                        <a href={carrerfourPDF} target={'_blank'} download={'Carrerfour per Settimane Fairtrade'}>CARREFOUR</a>
                    </div>
                </li>

               <li onClick={() => openInNewTab(coopJpg)} >
                   {/*<li >*/}
                    <div className={'discount-rate'}>fino al 20%</div>
                    <div className={'coop'}>
                        <StaticImage
                            src="../../images/partners/coop-logo.png"
                            alt="COOP"
                            placeholder="blurred"
                        />
                    </div>
                    <div>
                        <p>scopri i prodotti da</p>
                        {/*<span>COOP</span>*/}
                        <a href={coopJpg} target={'_blank'}>COOP</a>
                    </div>
                </li>

                <li onClick={() => openInNewTab(insJpg)} >
                    <div className={'discount-rate'}></div>
                    <div className={'ins'}>
                            <StaticImage
                                src="../../images/partners/INS-logo.png"
                                alt="INS"
                                placeholder="blurred"
                            />
                    </div>
                    <div>
                        <p>scopri i prodotti da</p>
                        {/*<span>INS</span>*/}
                        <a href={insJpg} target={'_blank'}>INS</a>
                    </div>
                </li>

                <li onClick={() => openInNewTab(lidlPdf)} >
                    <div className={'discount-rate'}>promozioni</div>
                    <div className={'lidl'}>
                        {/*<a href={lidlPdf} target={'_blank'}>*/}
                        <StaticImage
                            src="../../images/partners/lidl-logo.png"
                            alt="Lidl"
                            placeholder="blurred"
                        />
                        {/*</a>*/}
                    </div>
                    <div>
                        <p>scopri i prodotti da</p>
                        {/*<span>LIDL</span>*/}
                        <a href={lidlPdf} target={'_blank'}>LIDL</a>
                    </div>
                </li>

                {/*<li onClick={() => openInNewTab('https://images.selex-insegne.stormreply.com/mediaObject/dimar/Volantini/Mercat%C3%B2/230x288MERCATO17OTT100d/original/230x288MERCATO17OTT100d.pdf')} >*/}
                <li >
                    <div className={'discount-rate'}></div>
                    <div className={'mercato'}>
                        <StaticImage
                            src="../../images/partners/mercato-logo.png"
                            alt="Mercatò"
                            placeholder="blurred"
                        />
                    </div>
                    <div>
                        <p>scopri i prodotti da</p>
                        <span>MERCATO'</span>
                        {/*<a href="https://images.selex-insegne.stormreply.com/mediaObject/dimar/Volantini/Mercat%C3%B2/230x288MERCATO17OTT100d/original/230x288MERCATO17OTT100d.pdf" target={'_blank'}>MERCATO'</a>*/}
                    </div>

                </li>
                <li onClick={() => openInNewTab(pamJpg)} >
                    {/* <li>*/}
                    <div className={'discount-rate'}>promozioni</div>
                    <div className={'pam'}>
                        <StaticImage
                            src="../../images/partners/pam-logo.png"
                            alt="PAM"
                            placeholder="blurred"
                        />
                    </div>
                    <div>
                        <p>scopri i prodotti da </p>
                        {/*<span>PAM</span>*/}
                        <a href={pamJpg} target={'_blank'}>PAM</a>
                    </div>
                </li>
            </ul>

            <div className={'closure'}>
                <p>Cerca i prodotti certificati Fairtrade nel sito o nel volantino e recati nei loro punti vendita: </p>
                <ul>
                    <li>
                        è un'<strong>occasione per provarli</strong>, se non li hai mai acquistati prima <br/>
                        <em>
                            OPPURE
                        </em>
                    </li>
                    <li>
                        per <strong>fare scorta</strong> dei tuoi prodotti preferiti.
                    </li>
                </ul>
            </div>

            {isOn ?
                <p className={'thank-you'}>Grazie del tuo contributo! <img src={thankYou}
                                                                           alt="Fairtrade Italia - Grazie del tuo contributo!"/>
                </p>
                :
                <button onClick={toggleIsOn}>
                    andrò a fare la spesa durante le Settimane Fairtrade
                </button>
            }


            <div className={'overlay-cart-vegetables'}>
                <img src={vegetables} alt="Fairtrade Italia - vegetables" width={'191'} height={'260'} />
            </div>
            <div className={'overlay-cart-rice'}>
                <img src={rice} alt="Fairtrade Italia - black rice" width={'210'} height={'239'} />
            </div>
        </section>
    )
}